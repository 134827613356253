(function ($) {

  $.fn.fUpload = function (source, options) {

    const settings = $.extend({
      'readonly':   false,
    }, options);

    const id = this.attr('id');
    const element = this[0];

    let str = '';
    if (settings.readonly === false) {
      str = '<i class=\'removeAtt pull-right fa fa-trash-o\' class=\'pointer-cursor\'></i>';
    }

    for (let i = 0; i < source.length; i++) {
      this.append(
        '<div class=\'alert alert-success\' class=\'success-alert\' data-id=\'' + source[i].id + '\' id=\'c' + id + source[i].id + '\'>' +
                   '<a class=\'downloadAtt\' class=\'white\' target=\'_blank\' href=\'' + source[i].href + '\'><i class=\'fa fa-download\'></i> ' + source[i].name + '</a>' +
                   str +
                '</div>'
      );
    }

    if (settings.readonly === false) {
      element.insertAdjacentHTML('beforeend', '<div class="custom-file mb-1" id="inputfield_0">' +
        '<input type=\'file\' name=\'' + id + '[0]\' id=\'' + id + '0\' class=\'custom-file-input\'/>' +
        '<label class="custom-file-label" for="customFile">Datei auswählen</label>' +
      '</div>');

      let i = 1;
      this.delegate('input', 'change', function () {
        const obj = document.getElementById(id + (i - 1));

        obj.closest('.custom-file').classList.add('d-none');

        element.insertAdjacentHTML('beforeend',
          '<div class=\'alert alert-success\' class=\'success-alert\' id=\'c' + obj.id + '\'>' +
                        obj.value.split('\\').pop() + '<i class=\'fa fa-trash cancelAtt pull-right\' class=\'pointer-cursor\'></i>' +
                    '</div>'
        );

        element.insertAdjacentHTML('beforeend', '<div class="custom-file mb-1" id="inputfield_' + i + '">' +
          '<input type=\'file\' name=\'' + id + '[' + i + ']\' id=\'' + id + i + '\' class=\'custom-file-input\' />' +
          '<label class="custom-file-label" for="customFile">Datei auswählen</label>' +
        '</div>');

        i++;
      });

      this.delegate('.removeAtt', 'click', function () {
        const $parent = $(this).parent();

        $parent.after('<div class="custom-file d-none">' +
          '<input type=\'hidden\' name=\'removeAtt[' + $parent.data('id') + ']\' class=\'custom-file-input\' />' +
          '<label class="custom-file-label" for="customFile">Datei auswählen</label>' +
        '</div>');
        $parent.hide(500);
      });

      this.delegate('.cancelAtt', 'click', function () {
        const $parent = $(this).parent();

        $parent.hide(500, function () { $(this).remove(); });
      });
    }
  };

})(jQuery);
